footer {
    background-color: #555555;
    color: #BBBBBB;
    line-height: 1.5;
}

footer a {
    text-decoration: none;
    color: #EEEEEE;
}

.app-footer-title {
    color: #fff;
    font-size: 1.375rem;
    padding-bottom: 0.625rem;
}

.app-footer-main {
    padding: 1.25rem 1.875rem;
    display: flex;
    flex-wrap: wrap;
}

.app-footer-item {
    padding: 1.25rem;
    min-width: 12.5rem;
}

.app-footer-social {
    padding: 0 1.875rem 1.25rem;
}

.app-footer-social-list {
    display: flex;
    justify-content: center;
    border-top: 1px #777 solid;
    padding-top: 1.25rem;
}

.app-footer-social-list li {
    margin: 0.5rem;
    font-size: 1.25rem;
}

.app-footer-social-logo {
    filter: invert(100%) sepia(2%) saturate(7499%) hue-rotate(155deg) brightness(113%) contrast(101%);
    width: 25px;
    font-size: 20px;
}

.app-footer-legal {
    padding: 0.9375rem 1.875rem;
    background-color: #333;
}

.app-footer-legal-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.app-footer-legal-list li {
    margin: 0.125rem 0.625rem;
    white-space: nowrap;
}

.app-footer-legal-list li:nth-last-child(2) {
    flex: 1;
}

@media only screen and (max-width: 480px) {
    .app-footer-main {
        justify-content: left;
    }
}

@media only screen and (min-width: 480px) {
    .app-footer-main {
        justify-content: space-evenly;
    }
}