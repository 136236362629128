.App {
    text-align: center;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

ul {
    list-style: none;
    padding-left: 0;
}

div a {
    text-decoration: none;
    color: #229EFE;
}

a:hover {
    text-decoration: underline;
}

.app-link {
    text-decoration: none;
}

.app-body {
    text-align: center;
    min-height: 100vh;
    flex: 1;
}

.app-hero-text {
    margin-top: 30px;
    font-size: 32px;
    font-weight: bold;
}

.app-hero-subtext {
    margin-top: 15px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.app-footnote {
    margin-top: 5px;
    font-size: 16px;
    margin-bottom: 30px;
}

.app-button {
    margin: auto 15px;
    padding: 10px 5px 10px 5px;
    font-size: 20px;
    cursor: pointer;
    color: #FFFFFF;
    background-color: #415F69;
    border-radius: 5px;
}

.app-button-disabled {
    margin: auto 15px;
    padding: 10px 5px 10px 5px;
    font-size: 20px;
    cursor: not-allowed;
    color: #FFFFFF;
    background-color: #888888;
    border-radius: 5px;
}

@media only screen and (max-width: 480px) {
    div.large-screen {
        display: none;
    }

    p.large-screen {
        display: none;
    }

    div.small-screen {
        display: initial;
    }

    p.small-screen {
        display: inline-block;
    }
}

@media only screen and (min-width: 480px) {
    div.large-screen {
        display: initial;
    }

    p.large-screen {
        display: inline-block;
    }

    div.small-screen {
        display: none;
    }

    p.small-screen {
        display: none;
    }
}