.app-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: .45rem;
    background-color: #282c34;
    flex-wrap: wrap;
}

.app-header-section {
    display: flex;
    align-items: center;
}

.app-header-item {
    margin: auto 15px;
    padding: 5px 15px;
    font-size: 16px;
}

.app-header-item+.app-header-item {
    margin-left: 5px;
}

.app-header-item.is-app-header-item-selected {
    color: #FFFFFF;
    background-color: #415F69;
    border-radius: 5px;
}

.app-header-button {
    cursor: pointer;
    color: #D9E9EF;
}

.app-header-logo {
    width: auto;
    margin-left: 0px;
    margin-right: 0px;
}

.app-header-company-name {
    background: linear-gradient(to right, #68E0CF, #229EFE, #68E0CF);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.app-header-menu {
    width: 25px;
}

@media only screen and (max-width: 480px) {
    .app-header-logo {
        height: 50px;
    }

    .app-header-company-name {
        font-size: 20px;
    }
}

@media only screen and (min-width: 480px) {
    .app-header-logo {
        height: 75px;
    }

    .app-header-company-name {
        font-size: 36px;
    }
}