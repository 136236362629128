.intro-list {
    text-align: center;
    margin-top: 20px;
    width: 100%;
    display: inline-block;
    justify-content: center;
    align-items: center;
}

.intro-list-section {
    font-size: 100px;
    background: linear-gradient(to right, #A7A7A7, #F0F0F0, #A7A7A7);
    display: inline-block;
    border-style: solid;
    border-color: #000000;
    border-width: 2px;
    width: 80%;
    height: 100%;
    padding: 15px;
    margin: 10px;
}

.intro-list-section+.intro-list-section {
    margin: 10px;
}

.intro-list-section-photo {
    display: flex;
    background-color: #FFFFFF;
    border-style: solid;
    border-color: #000000;
    border-width: 2px;
    width: 100%;
}

.intro-list-section-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    padding: 10px;
}

.intro-list-section-text-container {
    width: 100%;
    height: 100%;
}

.intro-list-section-title {
    text-align: center;
    width: 100%;
    height: 100%;
    font-size: .34em;
    padding-top: 10px;
}

.intro-list-section-subtitle {
    font-size: .215em;
    font-weight: bold;
    padding-top: 20px;
}

.intro-list-section-text {
    font-size: .2em;
    padding-top: 20px;
}

.intro-list-link {
    font-size: 18px;
    padding-top: 20px;
    bottom: 30px;
}