  .app-intro-swiper {
      width: 80%;
      height: 300px;
  }

  .app-intro-swiper-custom-pagination {
      padding-top: 20px;
  }

  .app-intro-swiper-pagination-bullet {
      background-color: #FFFFFF;
      border-style: solid;
      border-color: #000000;
      border-width: 2px;
      width: 15px;
      height: 15px;
  }

  .app-intro-swiper-pagination-bullet-active {
      background-color: #000000;
      width: 15px;
      height: 15px;
  }

  .app-intro-swiper-slide {
      text-align: center;
      font-size: 100px;
      background: linear-gradient(to right, #A7A7A7, #F0F0F0, #A7A7A7);
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .app-intro-swiper-slide-content {
      border-style: solid;
      border-color: #000000;
      border-width: 2px;
      width: 100%;
      height: 100%;
      padding: 15px;
  }

  .app-intro-swiper-slide-photo {
      background-color: #FFFFFF;
      border-style: solid;
      border-color: #000000;
      border-width: 2px;
      width: 40%;
      height: 100%;
      float: left;
  }

  .app-intro-swiper-slide-image {
      object-fit: cover;
      width: 100%;
      height: 100%;
      padding: 10px;
  }

  .app-intro-swiper-slide-text-container {
      text-align: left;
      width: 60%;
      height: 100%;
      float: right;
  }

  .app-intro-swiper-slide-title {
      font-size: .325em;
      padding-top: 10px;
      padding-left: 20px;
  }

  .app-intro-swiper-slide-subtitle {
      font-size: .195em;
      font-weight: bold;
      padding-top: 20px;
      padding-left: 20px;
  }

  .app-intro-swiper-slide-text {
      font-size: .195em;
      padding-top: 20px;
      padding-left: 20px;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      @supports (-webkit-line-clamp: 4) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
      }
  }

  .app-intro-swiper-slide-link {
      font-size: 18px;
      padding-top: 20px;
      padding-left: 20px;
      position: absolute;
      bottom: 30px;
  }